/* -----------------------------------------------------------------------------
 *
 * Components: Split Section Floating
 *
 * ----------------------------------------------------------------------------- */

@import '../../../../styles/utilities/common.css';

.component {
  padding-top: 30px;
  padding-bottom: 0;
  font-family: var( --font-primary );

  @media (--medium) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */

/* override */
.component > .component__background {
  @media (max-width: 1023px) {
    padding-right: 0;
    padding-left: 0;
  }
}

/* -----------------------------------------------------------------------------
 * Info
 * ----------------------------------------------------------------------------- */

.component__info {
  padding: 16px;

  @media (--medium) {
    padding: 26px 30px 54px 30px;
  }
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.component__title {
  margin-bottom: 16px;
  color: var( --color-electric-blue );
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  @media (--medium) {
    font-size: 42px;
    line-height: 50px;
  }
}

/* -----------------------------------------------------------------------------
 * Content Container
 * ----------------------------------------------------------------------------- */

.component__layout {
  @media (--medium) {
    flex-wrap: nowrap;
  }
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.component__content {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (--medium) {
    font-size: 18px;
    line-height: 26px;
  }
}

.component__content span {
  line-height: 0;
}

/* -----------------------------------------------------------------------------
 * Img Wrapper
 * ----------------------------------------------------------------------------- */

.component__imgWrapper {
  position: relative;
  width: 100%;
  height: 323px;

  @media (--medium) {
    height: 100%;
  }
}

/* -----------------------------------------------------------------------------
 * Header Tooltip
 * ----------------------------------------------------------------------------- */

.component__headerTooltip {
  font-size: 1rem;
}
