/* -----------------------------------------------------------------------------
 *
 * Component: Split Section
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/utilities/common.css';

.component {
  display: flex;
  position: relative;
  flex-flow: column-reverse;
  padding: 0;
}

.componentMobileReverse {
  flex-flow: column;
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.component__content {
  padding: 60px 0;

  @media (--large) {
    padding: 80px 0;
    padding-left: 32.5px;
  }
}

.component__contentReverse {
  @media (--large) {
    padding-right: 32.5px;
    padding-left: 0;
  }
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.component__title {
  margin-bottom: 15px;
  padding: 0;
  font-weight: 700;
}

.component__title span {

  @media (--medium) {
    font-size: rem( 35px );
    line-height: lh( 35px, 50px );
  }
}

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.component__subtitle {
  margin-bottom: 30px;
}

/* -----------------------------------------------------------------------------
 * Item heading legal
 * ----------------------------------------------------------------------------- */

.component__itemHeading sup {
  position: relative;
  top: -10px;
  font-size: 50%;
}

/* -----------------------------------------------------------------------------
 * List
 * ----------------------------------------------------------------------------- */

.component__list {
  margin-bottom: 30px;
  padding: 0;
  list-style-type: none;
  line-height: lh( 16px, 20px );
}

.component__list li {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

/* -----------------------------------------------------------------------------
 * Icon
 * ----------------------------------------------------------------------------- */

.component__icon {
  margin-right: 10px;
  color: var( --color-primary );
  font-size: rem( 17px );
}

.component__icon__red,
.component__icon__dark {
  color: var( --color-electric-blue );
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

.component__legal {
  margin-bottom: 40px;
}

.component__buttonStyle {
  @media (--large) {
    width: 100%;
  }

  @media (--hd) {
    width: 48%;
  }
}

/* -----------------------------------------------------------------------------
 * Background Wrap
 * ----------------------------------------------------------------------------- */

.component__backgroundWrap {
  @mixin backgroundLeft;
  right: 0;

  img {
    @media (min-width: 1780px) {
      object-fit: contain !important;
      object-position: right !important;
    }
  }
}

.component__backgroundWrapReverse {
  left: 0;

  img {
    @media (min-width: 1780px) {
      object-fit: contain !important;
      object-position: left !important;
    }
  }
}

.component__backgroundWrapMobile {
  display: block;
}

.component__backgroundWrapContain img {
  object-fit: contain;
}

/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */

.component__background {
  position: relative;
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
    object-position: center top;
    position: absolute;
    width: 100%;
    height: 100%;

    @media (min-width: 1780px) {
      object-fit: contain;
      object-position: left;
    }
  }
}

/* -----------------------------------------------------------------------------
 * Background Mobile
 * ----------------------------------------------------------------------------- */

.component__backgroundMobile {
  @mixin backgroundMobile;
}
