/* -----------------------------------------------------------------------------
 *
 * Components: Split Section Gamers
 *
 * ----------------------------------------------------------------------------- */
@import '../index.module.css';
@import '../SplitSectionFloating/index.module.css';

.component {
  padding-top: 30px;
  padding-bottom: 0;

  @media (--medium) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.component__layout {
  display: flex;
  flex-direction: column;

  @media (--medium) {
    flex-direction: row;
  }
}

.component__info {
  padding: 64px 32px;

  @media (--medium) {
    padding: 82px 32px;
  }
  @media (--large) {
    padding: 64px;
  }
}

.component__imgWrapper {
  position: relative;

  @media(--medium-max) {
    max-height: 300px;
  }

  @media(--medium) {
    max-width: 360px;
    height: auto;
  }

  @media (--large) {
    max-width: 420px;
  }

  @media (--hd) {
    max-width: 400px;
  }
}

.component__title {
  margin-bottom: 20px;
  color: var( --color-electric-blue );
  font-family: var(--font-bandwidth);

  @media (--medium) {
    font-size: 32px;
    line-height: 32px;
  }

  @media (--large) {
    font-size: 54px;
    line-height: 68px;
  }
}

.component__subtitle {
  color: var( --color-white );
  padding-top: 0;
}

.component__list li {
  color: var( --color-white );
  margin-bottom: 16px;
}