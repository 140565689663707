.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
/* -----------------------------------------------------------------------------
 *
 * Component: Split Section
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.SplitSection_u-color-white__vNlVS {
  color: var( --color-white );
}

.SplitSection_u-color-midnight__mRYnB {
  color: var( --color-midnight-express );
}

.SplitSection_u-color-blue__c1hvT {
  color: var( --color-electric-blue );
}

.SplitSection_u-color-red__iZ4st {
  color: var( --color-torch-red );
}

/* text bolding */

.SplitSection_u-text-bold__OC4a4 {
  font-weight: bold;
}

/* text-decoration color */

s.SplitSection_u-textdecoration-color-white__On7xm {
  text-decoration-color:  var( --color-white );
}

s.SplitSection_u-textdecoration-color-midnight__rg12r {
  text-decoration-color:  var( --color-midnight-express );
}

s.SplitSection_u-textdecoration-color-blue__QxHty {
  text-decoration-color:  var( --color-electric-blue );
}

s.SplitSection_u-textdecoration-color-red__ZQIHF {
  text-decoration-color:  var( --color-torch-red );
}

.SplitSection_u-theme-white__bfOkS {
  background-color: var( --color-white );
}

.SplitSection_u-theme-light__AJbdl {
  background-color: var( --color-zircon );
}

.SplitSection_u-theme-black__7etZu {
  background-color: var( --color-black );
  color: var( --color-white );
}

.SplitSection_u-theme-dark__R6AiO {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.SplitSection_u-theme-light-blue__Px_xo {
  background-color: var( --surface-tertiary-mid );
}

.SplitSection_u-theme-blue__68_x7 {
  background-color: var( --color-electric-blue );
}

.SplitSection_u-theme-red__q10mf,
.SplitSection_u-theme-holiday__4QGDD {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.SplitSection_u-theme-neutral-light__Mp3Y9 {
  background-color: var( --color-neutral-lighter );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .SplitSection_hide-for-small-only__A8MHr {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .SplitSection_show-for-small-only__nN3JR {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .SplitSection_hide-for-medium__6Uf3x {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .SplitSection_show-for-medium__NZ34q {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .SplitSection_hide-for-medium-only__ixj0F {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .SplitSection_show-for-medium-only__tmfJh {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .SplitSection_hide-for-large__YMqOP {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .SplitSection_show-for-large__aOgpR {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .SplitSection_hide-for-large-only__uFjyI {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .SplitSection_show-for-large-only__OuVAS {
    display: none !important;
  }
}

.SplitSection_component__MMIEc {
  display: flex;
  position: relative;
  flex-flow: column-reverse;
  padding: 0;
}

.SplitSection_componentMobileReverse__Jb1Pm {
  flex-flow: column;
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.SplitSection_component__content__0aeDB {
  padding: 60px 0;
}

@media ( min-width: 1024px ) {

.SplitSection_component__content__0aeDB {
    padding: 80px 0;
    padding-left: 32.5px
}
  }

@media ( min-width: 1024px ) {

.SplitSection_component__contentReverse__pAC_N {
    padding-right: 32.5px;
    padding-left: 0
}
  }

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.SplitSection_component__title__6pP0q {
  margin-bottom: 15px;
  padding: 0;
  font-weight: 700;
}

@media ( min-width: 768px ) {

.SplitSection_component__title__6pP0q span {
    font-size: 2.1875rem;
    line-height: 1.43
}
  }

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.SplitSection_component__subtitle__5AriX {
  margin-bottom: 30px;
}

/* -----------------------------------------------------------------------------
 * Item heading legal
 * ----------------------------------------------------------------------------- */

.SplitSection_component__itemHeading__FDHdZ sup {
  position: relative;
  top: -10px;
  font-size: 50%;
}

/* -----------------------------------------------------------------------------
 * List
 * ----------------------------------------------------------------------------- */

.SplitSection_component__list__xTFMT {
  margin-bottom: 30px;
  padding: 0;
  list-style-type: none;
  line-height: 1.25;
}

.SplitSection_component__list__xTFMT li {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

/* -----------------------------------------------------------------------------
 * Icon
 * ----------------------------------------------------------------------------- */

.SplitSection_component__icon__7QVUP {
  margin-right: 10px;
  color: var( --color-primary );
  font-size: 1.0625rem;
}

.SplitSection_component__icon__red__qP3So,
.SplitSection_component__icon__dark__h_77t {
  color: var( --color-electric-blue );
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

.SplitSection_component__legal__P0dTA {
  margin-bottom: 40px;
}

@media ( min-width: 1024px ) {

.SplitSection_component__buttonStyle__abgY8 {
    width: 100%
}
  }

@media ( min-width: 1440px ) {

.SplitSection_component__buttonStyle__abgY8 {
    width: 48%
}
  }

/* -----------------------------------------------------------------------------
 * Background Wrap
 * ----------------------------------------------------------------------------- */

.SplitSection_component__backgroundWrap__qoAa_ {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
}

@media ( min-width: 768px ) {

.SplitSection_component__backgroundWrap__qoAa_ {
    display: block;
    width: 50vw;
}
  }

/* @media (--hd) {
    max-width: 100%;
  } */

.SplitSection_component__backgroundWrap__qoAa_ img {
    object-fit: cover;
    object-position: center;
  }

@media ( min-width: 768px ) {

.SplitSection_component__backgroundWrap__qoAa_ {
    position: absolute;
    right: var( --grid-gutter );
}
  }

.SplitSection_component__backgroundWrap__qoAa_{
  right: 0;
}

@media (min-width: 1780px) {

.SplitSection_component__backgroundWrap__qoAa_ img {
      object-fit: contain !important;
      object-position: right !important
  }
    }

.SplitSection_component__backgroundWrapReverse__gMP1n {
  left: 0;
}

@media (min-width: 1780px) {

.SplitSection_component__backgroundWrapReverse__gMP1n img {
      object-fit: contain !important;
      object-position: left !important
  }
    }

.SplitSection_component__backgroundWrapMobile__sxZIW {
  display: block;
}

.SplitSection_component__backgroundWrapContain__t8DUF img {
  object-fit: contain;
}

/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */

.SplitSection_component__background__lbyWS {
  position: relative;
  width: 100%;
  height: 100%;
}

.SplitSection_component__background__lbyWS video {
    object-fit: cover;
    object-position: center top;
    position: absolute;
    width: 100%;
    height: 100%;
  }

@media (min-width: 1780px) {

.SplitSection_component__background__lbyWS video {
      object-fit: contain;
      object-position: left
  }
    }

/* -----------------------------------------------------------------------------
 * Background Mobile
 * ----------------------------------------------------------------------------- */

.SplitSection_component__backgroundMobile__cIqwI {
  display: block;
  width: 100%;
  height: 230px;
}

@media ( min-width: 576px ) {

.SplitSection_component__backgroundMobile__cIqwI {
    height: 414px;
}
  }

@media ( min-width: 768px ) {

.SplitSection_component__backgroundMobile__cIqwI {
    display: none;
}
  }

/* -----------------------------------------------------------------------------
 *
 * Components: Split Section Gamers
 *
 * ----------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 *
 * Component: Split Section
 *
 * ----------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */
/* Media Queries */
/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */
@custom-media --xsmall( min-width: 320px );
/* Admin */
/* Mixins */
/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */
/* Color utils */
.SplitSectionGamers_u-color-white__Cn9LF {
  color: var( --color-white );
}
.SplitSectionGamers_u-color-midnight__5svnJ {
  color: var( --color-midnight-express );
}
.SplitSectionGamers_u-color-blue__Dzfjh {
  color: var( --color-electric-blue );
}
.SplitSectionGamers_u-color-red__H3hDX {
  color: var( --color-torch-red );
}
/* text bolding */
.SplitSectionGamers_u-text-bold__1L9g9 {
  font-weight: bold;
}
/* text-decoration color */
s.SplitSectionGamers_u-textdecoration-color-white__LN2vp {
  text-decoration-color:  var( --color-white );
}
s.SplitSectionGamers_u-textdecoration-color-midnight__G16R9 {
  text-decoration-color:  var( --color-midnight-express );
}
s.SplitSectionGamers_u-textdecoration-color-blue__SHQ_u {
  text-decoration-color:  var( --color-electric-blue );
}
s.SplitSectionGamers_u-textdecoration-color-red__ZmnXh {
  text-decoration-color:  var( --color-torch-red );
}
.SplitSectionGamers_u-theme-white__OB06S {
  background-color: var( --color-white );
}
.SplitSectionGamers_u-theme-light__M9gth {
  background-color: var( --color-zircon );
}
.SplitSectionGamers_u-theme-black__sS_NM {
  background-color: var( --color-black );
  color: var( --color-white );
}
.SplitSectionGamers_u-theme-dark__E1aaD {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}
.SplitSectionGamers_u-theme-light-blue__me0T0 {
  background-color: var( --surface-tertiary-mid );
}
.SplitSectionGamers_u-theme-blue__VCuNL {
  background-color: var( --color-electric-blue );
}
.SplitSectionGamers_u-theme-red__h89Kb,
.SplitSectionGamers_u-theme-holiday__foYJW {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}
.SplitSectionGamers_u-theme-neutral-light__t_BPy {
  background-color: var( --color-neutral-lighter );
}
/* Visibility utils (taken from Foundation) */
@media print, screen and (max-width: 39.99875em) {
  .SplitSectionGamers_hide-for-small-only__k87em {
    display: none !important;
  }
}
@media screen and (max-width: 0), screen and (min-width: 40em) {
  .SplitSectionGamers_show-for-small-only__Vajr5 {
    display: none !important;
  }
}
@media print, screen and (min-width: 40em) {
  .SplitSectionGamers_hide-for-medium__pPM1c {
    display: none !important;
  }
}
@media screen and (max-width: 39.99875em) {
  .SplitSectionGamers_show-for-medium__FvxfU {
    display: none !important;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .SplitSectionGamers_hide-for-medium-only__P0_kA {
    display: none !important;
  }
}
@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .SplitSectionGamers_show-for-medium-only__h_b2u {
    display: none !important;
  }
}
@media print, screen and (min-width: 64em) {
  .SplitSectionGamers_hide-for-large__MynCA {
    display: none !important;
  }
}
@media screen and (max-width: 63.99875em) {
  .SplitSectionGamers_show-for-large__mGB4P {
    display: none !important;
  }
}
@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .SplitSectionGamers_hide-for-large-only__z_MrJ {
    display: none !important;
  }
}
@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .SplitSectionGamers_show-for-large-only__YEFyX {
    display: none !important;
  }
}
.SplitSectionGamers_component__2vmOh {
  display: flex;
  position: relative;
  flex-flow: column-reverse;
  padding: 0;
}
.SplitSectionGamers_componentMobileReverse__gtNtb {
  flex-flow: column;
}
/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__content__TOhLe {
  padding: 60px 0;
}
@media ( min-width: 1024px ) {
.SplitSectionGamers_component__content__TOhLe {
    padding: 80px 0;
    padding-left: 32.5px
}
  }
@media ( min-width: 1024px ) {
.SplitSectionGamers_component__contentReverse__we23V {
    padding-right: 32.5px;
    padding-left: 0
}
  }
/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__title__2fcRo {
  margin-bottom: 15px;
  padding: 0;
  font-weight: 700;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__title__2fcRo span {
    font-size: 2.1875rem;
    line-height: 1.43
}
  }
/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__subtitle__h_Sd9 {
  margin-bottom: 30px;
}
/* -----------------------------------------------------------------------------
 * Item heading legal
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__itemHeading__QDNHK sup {
  position: relative;
  top: -10px;
  font-size: 50%;
}
/* -----------------------------------------------------------------------------
 * List
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__list__zRTpv {
  margin-bottom: 30px;
  padding: 0;
  list-style-type: none;
  line-height: 1.25;
}
.SplitSectionGamers_component__list__zRTpv li {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
/* -----------------------------------------------------------------------------
 * Icon
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__icon__6IulZ {
  margin-right: 10px;
  color: var( --color-primary );
  font-size: 1.0625rem;
}
.SplitSectionGamers_component__icon__red__6WIQX,
.SplitSectionGamers_component__icon__dark__0bUPA {
  color: var( --color-electric-blue );
}
/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__legal__Vckua {
  margin-bottom: 40px;
}
@media ( min-width: 1024px ) {
.SplitSectionGamers_component__buttonStyle___UAKa {
    width: 100%
}
  }
@media ( min-width: 1440px ) {
.SplitSectionGamers_component__buttonStyle___UAKa {
    width: 48%
}
  }
/* -----------------------------------------------------------------------------
 * Background Wrap
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__backgroundWrap__Un29y {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__backgroundWrap__Un29y {
    display: block;
    width: 50vw;
}
  }
/* @media (--hd) {
    max-width: 100%;
  } */
.SplitSectionGamers_component__backgroundWrap__Un29y img {
    object-fit: cover;
    object-position: center;
  }
@media ( min-width: 768px ) {
.SplitSectionGamers_component__backgroundWrap__Un29y {
    position: absolute;
    right: var( --grid-gutter );
}
  }
.SplitSectionGamers_component__backgroundWrap__Un29y{
  right: 0;
}
@media (min-width: 1780px) {
.SplitSectionGamers_component__backgroundWrap__Un29y img {
      object-fit: contain !important;
      object-position: right !important
  }
    }
.SplitSectionGamers_component__backgroundWrapReverse__c8qVX {
  left: 0;
}
@media (min-width: 1780px) {
.SplitSectionGamers_component__backgroundWrapReverse__c8qVX img {
      object-fit: contain !important;
      object-position: left !important
  }
    }
.SplitSectionGamers_component__backgroundWrapMobile__9loWD {
  display: block;
}
.SplitSectionGamers_component__backgroundWrapContain__S_9Cr img {
  object-fit: contain;
}
/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__background__FyeWo {
  position: relative;
  width: 100%;
  height: 100%;
}
.SplitSectionGamers_component__background__FyeWo video {
    object-fit: cover;
    object-position: center top;
    position: absolute;
    width: 100%;
    height: 100%;
  }
@media (min-width: 1780px) {
.SplitSectionGamers_component__background__FyeWo video {
      object-fit: contain;
      object-position: left
  }
    }
/* -----------------------------------------------------------------------------
 * Background Mobile
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__backgroundMobile___Pdam {
  display: block;
  width: 100%;
  height: 230px;
}
@media ( min-width: 576px ) {
.SplitSectionGamers_component__backgroundMobile___Pdam {
    height: 414px;
}
  }
@media ( min-width: 768px ) {
.SplitSectionGamers_component__backgroundMobile___Pdam {
    display: none;
}
  }
/* -----------------------------------------------------------------------------
 *
 * Components: Split Section Floating
 *
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__2vmOh {
  padding-top: 30px;
  padding-bottom: 0;
  font-family: var( --font-primary );
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__2vmOh {
    padding-top: 72px;
    padding-bottom: 72px
}
  }
/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */
/* override */
@media (max-width: 1023px) {
.SplitSectionGamers_component__2vmOh > .SplitSectionGamers_component__background__FyeWo {
    padding-right: 0;
    padding-left: 0
}
  }
/* -----------------------------------------------------------------------------
 * Info
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__info__R1v9y {
  padding: 16px;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__info__R1v9y {
    padding: 26px 30px 54px 30px
}
  }
/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__title__2fcRo {
  margin-bottom: 16px;
  color: var( --color-electric-blue );
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__title__2fcRo {
    font-size: 42px;
    line-height: 50px
}
  }
/* -----------------------------------------------------------------------------
 * Content Container
 * ----------------------------------------------------------------------------- */
@media ( min-width: 768px ) {
.SplitSectionGamers_component__layout__iBmrS {
    flex-wrap: nowrap
}
  }
/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__content__TOhLe {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__content__TOhLe {
    font-size: 18px;
    line-height: 26px
}
  }
.SplitSectionGamers_component__content__TOhLe span {
  line-height: 0;
}
/* -----------------------------------------------------------------------------
 * Img Wrapper
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__imgWrapper__0_G8Z {
  position: relative;
  width: 100%;
  height: 323px;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__imgWrapper__0_G8Z {
    height: 100%
}
  }
/* -----------------------------------------------------------------------------
 * Header Tooltip
 * ----------------------------------------------------------------------------- */
.SplitSectionGamers_component__headerTooltip__Uk4cw {
  font-size: 1rem;
}
.SplitSectionGamers_component__2vmOh {
  padding-top: 30px;
  padding-bottom: 0;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__2vmOh {
    padding-top: 100px;
    padding-bottom: 100px
}
  }
.SplitSectionGamers_component__layout__iBmrS {
  display: flex;
  flex-direction: column;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__layout__iBmrS {
    flex-direction: row
}
  }
.SplitSectionGamers_component__info__R1v9y {
  padding: 64px 32px;
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__info__R1v9y {
    padding: 82px 32px
}
  }
@media ( min-width: 1024px ) {
.SplitSectionGamers_component__info__R1v9y {
    padding: 64px
}
  }
.SplitSectionGamers_component__imgWrapper__0_G8Z {
  position: relative;
}
@media( max-width: 767px ) {
.SplitSectionGamers_component__imgWrapper__0_G8Z {
    max-height: 300px
}
  }
@media( min-width: 768px ) {
.SplitSectionGamers_component__imgWrapper__0_G8Z {
    max-width: 360px;
    height: auto
}
  }
@media ( min-width: 1024px ) {
.SplitSectionGamers_component__imgWrapper__0_G8Z {
    max-width: 420px
}
  }
@media ( min-width: 1440px ) {
.SplitSectionGamers_component__imgWrapper__0_G8Z {
    max-width: 400px
}
  }
.SplitSectionGamers_component__title__2fcRo {
  margin-bottom: 20px;
  color: var( --color-electric-blue );
  font-family: var(--font-bandwidth);
}
@media ( min-width: 768px ) {
.SplitSectionGamers_component__title__2fcRo {
    font-size: 32px;
    line-height: 32px
}
  }
@media ( min-width: 1024px ) {
.SplitSectionGamers_component__title__2fcRo {
    font-size: 54px;
    line-height: 68px
}
  }
.SplitSectionGamers_component__subtitle__h_Sd9 {
  color: var( --color-white );
  padding-top: 0;
}
.SplitSectionGamers_component__list__zRTpv li {
  color: var( --color-white );
  margin-bottom: 16px;
}
